import {
  Container,
  Header,
  ColumnLayout,
  Box,
  SpaceBetween,
  Button
} from '@cloudscape-design/components';
import React from 'react';
import PropTypes from 'prop-types';

const GeneralConfig = (props) => (
  <Container header={
    <SpaceBetween direction='vertical' size='s'>
      <Header variant='h2'>Policy Info</Header>
      <Button variant='normal' onClick={props.handlePolicyChange}>Select New Policy</Button>
    </SpaceBetween>
  }>
    <ColumnLayout columns={3} variant="text-grid">
      <div>
        <Box variant="awsui-key-label">Policy</Box>
        <div>{props.policyNum}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Insured</Box>
        <div>{props.insuredName}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Address</Box>
        <div>{props.insuredAddress}</div>
      </div>
    </ColumnLayout>
  </Container>
);

GeneralConfig.propTypes = {
  policyNum: PropTypes.string.isRequired,
  insuredName: PropTypes.string.isRequired,
  insuredAddress: PropTypes.string.isRequired,
  handlePolicyChange: PropTypes.func.isRequired
};

export default GeneralConfig;