import { API } from 'aws-amplify';
import cognitoConfig from '../cognito';
import axios from 'axios';

// Anything common between the different endpoints should go here
const baseRequest = {
  response: true
};

export const fetchPolicyInfo = async (policyNumber) => {
  
  const request = {
    ...baseRequest
  };

  return await API.get(cognitoConfig.apiName, `/policy/${policyNumber}`, request);
  
};

export const processPolicyPhotos = async (policyNumber) => {

  const request = {
    ...baseRequest
  };

  return await API.put(cognitoConfig.apiName, `/policy/${policyNumber}`, request);
};

export const fetchPolicyPhotos = async (policyNumber) => {
  
  const request = {
    ...baseRequest
  };

  return await API.get(cognitoConfig.apiName, `/photo/${policyNumber}`, request);
};

export const allocateS3PhotoURLs = async (policyNumber, photosList) => {

  const photosBody = [];

  for (const photo of photosList) {
    const photoRequestItem = {
      uploadID: photo.uploadID,
      fileName: photo.photoObject.name,
      fileType: photo.photoObject.type,
      location: photo.location,
      comment: photo.note
    };

    photosBody.push(photoRequestItem);
  }

  const request = {
    ...baseRequest,
    body: photosBody
  };

  return await API.post(cognitoConfig.apiName, `/photo/${policyNumber}`, request);
};

export const pushPhotoToS3 = async (photo, s3URL) => {
  const axiosConfig = {
    headers: {
      'Accept': '*/*',
      'Content-Type': photo.type
    },
    method: 'put',
    url: s3URL,
    data: photo
  };

  return await axios(axiosConfig);
};

export const editPhotoInfo = async (policyNumber, photoUUID, photoData) => {

  const request = {
    ...baseRequest,
    body: {
      location: photoData.location,
      comment: photoData.note
    }
  };

  return await API.put(cognitoConfig.apiName, `/photo/${policyNumber}/${photoUUID}`, request);
};

export const deletePhoto = async (policyNumber, photoUUID) => {

  const request = {
    ...baseRequest
  };

  return await API.del(cognitoConfig.apiName, `/photo/${policyNumber}/${photoUUID}`, request);
};
