import { useState, Fragment } from 'react';
import {
  FileUpload,
  Form,
  FormField,
  SpaceBetween,
  Button,
  Flashbar
} from '@cloudscape-design/components';
import PhotoView from './PhotoView';
import React from 'react';
import PropTypes from 'prop-types';
import { allocateS3PhotoURLs, pushPhotoToS3 } from '../api';

const MultiUpload = (props) => {

  const [photos, setPhotos] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [flashbarSettings, setFlashbarSettings] = useState([]);
  const policyNum = props.policyNum;


  const uploadFiles = async () => {

    setLoadingIndicator(true);
    setFlashbarSettings([{
      type: 'info',
      loading: true,
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setFlashbarSettings([]),
      content: (
        <>
          Uploading files. Please wait...
        </>
      ),
      id: 'message_0'
    }]);

    try {
      const allocateResponse = await allocateS3PhotoURLs(policyNum, photos);

      if (allocateResponse.status === 200) {

        for (const photo of photos) {
          const matchingResponseData = allocateResponse.data.find(responseDataElement => responseDataElement.uploadID === photo.uploadID);
          await pushPhotoToS3(photo.photoObject, matchingResponseData.url);
        }

        // Delay success message slightly to give photos time to process in AWS
        window.setTimeout(() => {
          setFlashbarSettings([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashbarSettings([]),
              content: (
                <>
                  Files uploaded successfully.
                </>
              ),
              id: 'message_upload_success'
            }
          ]);
          clearForm();
          setLoadingIndicator(false);
        }, 1000);

      } else {
        setFlashbarSettings([
          {
            type: 'error',
            header: 'Upload Failed',
            content: `Unable to allocate hosting resource for photo. Please try again. Contact help center if the issue persists. 
              Response code received was ${allocateResponse.status}`,
            dismissible: true,
            dissmissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarSettings([]),
            id: 'message_s3_allocate_error'
          }
        ]);
        setLoadingIndicator(false);
      }

    } catch (err) {
      setLoadingIndicator(false);
      setFlashbarSettings([
        {
          type: 'error',
          header: 'Upload Failed',
          content: `An error was encountered uploading photos. Please try again. Contact help center if the issue persists. 
            Response code received was ${err.response.status}`,
          dismissible: true,
          dissmissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarSettings([]),
          id: 'message_s3_allocate_error'
        }
      ]);
    }

  };


  const clearForm = () => {
    setPhotos([]);
  };


  const handleUploadChange = (event) => {

    const eventPhotos = event.detail.value;

    // We have to check if this event is getting fired because the user is removing a photo
    // If the event only has one photo, and that photo has the same name, size, and type as a photo already in the list, we will assume the user is trying to remove it
    // This should work in basically all cases, but here be dragons
    if (eventPhotos.length === 1) {
      const existingIndex = photos.findIndex((photo) =>
        photo.photoObject.size === eventPhotos[0].size &&
        photo.photoObject.name === eventPhotos[0].name &&
        photo.photoObject.type === eventPhotos[0].type);

      if (existingIndex !== -1) {

        // A matching photo exists - remove it
        const newPhotosList = [];
        for (let i = 0; i < photos.length; i++) {
          if (i !== existingIndex) {
            newPhotosList.push(photos[i]);
          }
        }

        setPhotos(newPhotosList);
        return;

      }
    }

    // If we make it here, that means the user is not removing a photo, so add whatever they selected to the list
    const newPhotos = [];
    let nextPhotoUploadID = 0;
    for (const photo of eventPhotos) {

      if (!photo.type.includes('image')) {
        continue;
      }

      const photoInfo = {
        photoObject: photo,
        uploadID: nextPhotoUploadID
      };

      nextPhotoUploadID++;
      newPhotos.push(photoInfo);

    }

    setPhotos(newPhotos);
  };


  return (
    <>
      <Flashbar items={flashbarSettings} />
      {
        loadingIndicator ?
          <></> :
          <SpaceBetween direction='vertical' size='xxs'>
            <form onSubmit={e => e.preventDefault()}>
              <Form
                variant="embedded"
                actions={
                  <SpaceBetween
                    direction="horizontal"
                    size="xs"
                  >
                    <Button
                      formAction="none"
                      onClick={() => clearForm()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={() => uploadFiles()}
                    >
                      Upload
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormField
                  label="Multi Photo Upload"
                  description="Choose as many photos to upload as you like. You can attach notes to the photos once selected."
                >
                  <FileUpload
                    onChange={handleUploadChange}
                    value={photos.map(photo => photo.photoObject)}
                    i18nStrings={{
                      uploadButtonText: e =>
                        e ? 'Choose files' : 'Choose file',
                      dropzoneText: e =>
                        e
                          ? 'Drop files to upload'
                          : 'Drop file to upload',
                      removeFileAriaLabel: e =>
                        `Remove file ${e + 1}`,
                      limitShowFewer: 'Show fewer files',
                      limitShowMore: 'Show more files',
                      errorIconAriaLabel: 'Error'
                    }}
                    multiple
                    tokenLimit={3}
                    constraintText="Files must be images"
                  />
                </FormField>
              </Form>
            </form>

            {photos.map((photoInfo) => {
              return (
                <PhotoView
                  key={photoInfo.uploadID}
                  photoUrl={URL.createObjectURL(photoInfo.photoObject)}
                  photoName={photoInfo.photoObject.name}
                  location={photoInfo.location}
                  note={photoInfo.note}
                  onNoteChange={(e) => {
                    const changedPhoto = photos.find(photo => photo.uploadID === photoInfo.uploadID);
                    changedPhoto.note = e.detail.value;
                    setPhotos([...photos]);
                  }}
                  onLocationChange={(e) => {
                    const changedPhoto = photos.find(photo => photo.uploadID === photoInfo.uploadID);
                    changedPhoto.location = e.detail.value;
                    setPhotos([...photos]);
                  }}
                />
              );
            })}
          </SpaceBetween>
      }
    </>
  );
};

MultiUpload.propTypes = {
  policyNum: PropTypes.string.isRequired
};

export default MultiUpload;