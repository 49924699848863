const cognitoConfig = {
  apiName: 'pmuAPI',
  cognitoRegion: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
  cognitoDomain: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
  cognitoUserPoolID: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
  cognitoUserPoolWebClientID: `${process.env.REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID}`,
  cognitoAuthFlowType: `${process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE}`,
  cognitoSigninURL: `${process.env.REACT_APP_COGNITO_SIGNIN_URL}`,
  cognitoFrontendURL: `${process.env.REACT_APP_FRONTEND_URL}`
};

export default cognitoConfig;