import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import '../package.json';
import {Amplify, Auth} from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import cognitoConfig from './cognito';
import AppContainer from './components/AppContainer';


Amplify.configure({
  Auth: {
    region: cognitoConfig.cognitoRegion,
    userPoolId: cognitoConfig.cognitoUserPoolID,
    userPoolWebClientId: cognitoConfig.cognitoUserPoolWebClientID,
    authenticationFlowType: cognitoConfig.cognitoAuthFlowType,

    oauth: {
      domain: cognitoConfig.cognitoDomain,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: cognitoConfig.cognitoFrontendURL,
      redirectSignOut: cognitoConfig.cognitoFrontendURL,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: cognitoConfig.apiName,
        endpoint: `${process.env.REACT_APP_BACKEND_API_URL}`,
        //Add authentication info to the auth header
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
    ]
  }
});

function App(){

  const urlString = cognitoConfig.cognitoSigninURL;

  // Authentication component
  const component = {
    // Override components of the sign in screen 
    SignIn: {
      Header: () => {
        return (
          // This line replaces the window with Cognito signin screen for automatic authentication
          // If a signin screen or other screen is desired, replace this line with the desired UI
          <>
            {window.location.replace(urlString)}
          </>
        );
      },
      Footer: () => {
        return (<></>);
      },
    },
  };

  return <>

    <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
    <Authenticator components={component} variation="modal" hideSignUp={true}>
      {() => (
        <AppContainer />
      )}
    </Authenticator>

  </>;
}

export default App;
