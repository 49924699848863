import {
  SpaceBetween,
  Input,
  Box,
  Container,
  FormField,
  Button,
  Spinner,
  Flashbar,
  Modal,
  Icon
} from '@cloudscape-design/components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { deletePhoto, editPhotoInfo } from '../api';

const PhotoView = (props) => {
  const photoUrl = props.photoUrl;
  const photoName = props.photoName;
  const onNoteChange = props.onNoteChange;
  const onLocationChange = props.onLocationChange;
  const editableInfo = props.editable;

  const [edited, setEdited] = useState(false);
  const [location, setLocation] = useState(props.location);
  const [note, setNote] = useState(props.note);
  const [initialLocation, setInitialLocation] = useState(props.location);
  const [initialNote, setInitialNote] = useState(props.note);
  const [showSpinner, setShowSpinner] = useState(false);
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLengthWarning, setShowLengthWarning] = useState(false);

  const handlePhotoChange = async () => {
    const photoData = {
      location: location,
      note: note
    };

    setShowSpinner(true);
    setButtonsDisabled(true);

    try {

      const response = await editPhotoInfo(props.policyNumber, props.UUID, photoData);
      setShowSpinner(false);
      setButtonsDisabled(false);

      if (response.status === 200) {

        setInitialLocation(photoData.location);
        setInitialNote(photoData.note);

        setEdited(false);
        setShowSuccess(true);

        window.setTimeout(() => setShowSuccess(false), 5000);

      } else {

        setFlashbarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
            content: (`An error was encountered saving your changes. Please try again. Contact help center if the issue persists. 
            Response code received was ${response.status}`),
            id: 'message_save_error'
          }
        ]);

      }

    } catch (err) {

      setShowSpinner(false);
      setButtonsDisabled(false);
      setFlashbarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([]),
          content: (`An error was encountered saving your changes. Please try again. Contact help center if the issue persists. 
          Response code received was ${err.response.status}`),
          id: 'message_save_error'
        }
      ]);

    }

  };

  const handleDeletePhoto = async () => {

    setShowConfirmation(false);
    setShowSpinner(true);
    setButtonsDisabled(true);

    try {
      const response = await deletePhoto(props.policyNumber, props.UUID);
      setShowSpinner(false);

      if (response.status === 200) {
        props.removePhoto(props.UUID);
      } else {

        setButtonsDisabled(false);
        setFlashbarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
            content: (`An error was encountered deleting the photo. Please try again. Contact help center if the issue persists. 
            Response code received was ${response.status}`),
            id: 'message_delete_error'
          }
        ]);

      }
    } catch (err) {

      setButtonsDisabled(false);
      setFlashbarItems([
        {
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([]),
          content: (`An error was encountered deleting the photo. Please try again. Contact help center if the issue persists. 
            Response code received was ${err.response.status}`),
          id: 'message_delete_error'
        }
      ]);

    }

  };

  return (
    <Container
      media={{
        content: (
          <img
            src={photoUrl}
            alt={photoName}
          />
        ),
        width: '50%',
        position: 'side'
      }}
    >
      <Modal visible={showConfirmation} onDismiss={() => setShowConfirmation(false)} header='Delete photo?' footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={() => setShowConfirmation(false)} variant="primary">Cancel</Button>
            <Button onClick={handleDeletePhoto} variant="link">Confirm</Button>
          </SpaceBetween>
        </Box>
      }>
        Are you sure you want to delete this photo? You will need to re-upload if you wish to send it to PIMS.
      </Modal>
      <SpaceBetween direction="vertical" size="s">
        <SpaceBetween direction="vertical" size="xxs">
          <Box variant="h2" fontSize={window.innerWidth < 768 ? 'heading-xs' : 'heading-l'}>{photoName}</Box>
        </SpaceBetween>
        <FormField
          description="Location"
        >
          {
            editableInfo ?
              <Input onChange={(event) => {
                if (event.detail.value.length < 31) {
                  setLocation(event.detail.value);
                } else {
                  if (!showLengthWarning) {
                    setShowLengthWarning(true);
                    window.setTimeout(() => setShowLengthWarning(false), 3000);
                  }
                }
                if (!edited) {
                  setEdited(true);
                }
              }} value={location} /> :
              <Input onChange={(event) => {
                if (event.detail.value.length < 31) {
                  onLocationChange(event);
                } else {
                  if (!showLengthWarning) {
                    setShowLengthWarning(true);
                    window.setTimeout(() => setShowLengthWarning(false), 3000);
                  }
                }
              }} value={props.location} />
          }
          {
            showLengthWarning ?
              <small>This field has a max length of 30 characters.</small> :
              <></>
          }
        </FormField>
        <FormField
          description="Notes"
        >
          {
            editableInfo ?
              <Input onChange={(event) => {
                setNote(event.detail.value);
                if (!edited) {
                  setEdited(true);
                }
              }} value={note} /> :
              <Input onChange={onNoteChange} value={props.note} />
          }
        </FormField>
        <SpaceBetween direction='horizontal' size='m'>
          {
            editableInfo && edited ?
              <>
                <Button disabled={buttonsDisabled} onClick={handlePhotoChange}>Save</Button>
                <Button disabled={buttonsDisabled} onClick={() => {
                  setLocation(initialLocation);
                  setNote(initialNote);
                  setEdited(false);
                }}>
                  Reset
                </Button>
              </>
              :
              <></>
          }
        </SpaceBetween>
        {
          editableInfo ?
            <Button disabled={buttonsDisabled} onClick={() => setShowConfirmation(true)} variant='link'>Delete</Button> :
            <></>
        }
        {
          showSpinner ?
            <Spinner /> :
            <></>
        }
        {
          showSuccess ?
            <span style={{ color: '#29ad32' }}>
              <Icon name='check' variant='success' />
              <span style={{ marginLeft: '5px' }}>Success!</span>
            </span> :
            <></>
        }
        <Flashbar items={flashbarItems} />
      </SpaceBetween>
    </Container>
  );
};

PhotoView.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  photoName: PropTypes.string,
  location: PropTypes.string,
  note: PropTypes.string,
  onNoteChange: PropTypes.func,
  onLocationChange: PropTypes.func,
  editable: PropTypes.bool,
  UUID: PropTypes.string,
  policyNumber: PropTypes.string,
  removePhoto: PropTypes.func
};

export default PhotoView;