import React, { useState } from 'react';
import { Form, Container, Header, Input, FormField, Button, SpaceBetween } from '@cloudscape-design/components';
import PropTypes from 'prop-types';

const PolicyNumberInput = (props) => {

  const [policyInput, setPolicyInput] = useState('');

  return (
    <Container header={
      <Header description="Enter the policy number which you are inspecting for.">
        Enter Policy Number
      </Header>
    }>
      <form onSubmit={e => e.preventDefault()}>
        <Form>
          <SpaceBetween direction='vertical' size='m'>
            <FormField>
              <Input value={policyInput} onChange={e => setPolicyInput(e.detail.value)} />
            </FormField>
            <Button variant='primary' disabled={props.disabled} formAction='none' onClick={() => {
              props.handlePolicyNumberSubmit(policyInput);
            }}>
              Submit
            </Button>
            {
              process.env.REACT_APP_ENVIRONMENT === 'dev' ?
                <Button variant='normal' onClick={() => {
                  const generatedNumber = Math.floor(Math.random() * 10000000000);
                  setPolicyInput(generatedNumber);
                }}>Generate Policy Number</Button> :
                <></>
            }
          </SpaceBetween>
        </Form>
      </form>
    </Container>
  );
};

PolicyNumberInput.propTypes = {
  handlePolicyNumberSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default PolicyNumberInput;