import { AppLayout, ContentLayout, Header, SpaceBetween, Tabs, TopNavigation, Modal, Box, Button, Flashbar } from '@cloudscape-design/components';
import React, { useState, useEffect } from 'react';
import ShowPhotos from './ShowPhotos';
import MultiUpload from './MultipleUpload';
import GeneralConfig from './GeneralConfig';
import PolicyNumberInput from './PolicyNumberInput';
import { fetchPolicyInfo } from '../api';
import { Auth } from 'aws-amplify';

const AppContainer = () => {

  const [policyInfo, setPolicyInfo] = useState(undefined);
  const [displayModal, setDisplayModal] = useState(false);
  const [user, setUser] = useState();
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const user = await Auth.currentAuthenticatedUser();
      user.username = user.username.replace('kfbazuread_', '');
      setUser(user);
    };
    try {
      getUser();
    } catch (err) {
      setUser(undefined);
    }
  }, []);

  const handlePolicySubmit = async (policyNumber) => {
    try {
      setButtonDisabled(true);
      setFlashbarItems([
        {
          type: 'info',
          loading: true,
          dismissible: false,
          dismissLabel: 'Dismiss message',
          content: (
            <>
              Fetching Policy Information...
            </>
          ),
          id: 'message_fetching_policy'
        }
      ]);

      const response = await fetchPolicyInfo(policyNumber);

      if (response.status === 200) {
        const policyInfo = {
          policyNumber: response.data.policyID,
          insuredName: response.data.insuredName,
          address: response.data.address
        };
        setPolicyInfo(policyInfo);

        setFlashbarItems([]);
        
      } else {
        setFlashbarItems([
          {
            type: 'error',
            header: 'Policy fetch failed',
            content: `An error was encountered retrieving the information for this policy. Please try again. Contact help center if the issue persists. 
              Response code received was ${response.status}`,
            dismissible: true,
            dissmissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
            id: 'message_policy_fetch_error'
          }
        ]);
      }
    } catch (err) {

      if (err.response.status === 400 && err.response.data.message === 'Policy already pushed') {

        setFlashbarItems([
          {
            type: 'warning',
            header: 'Policy photos already pushed',
            content: 'The photos for this policy have already been pushed. Please choose another policy, or upload photos for this policy in PIMS.',
            dismissible: true,
            dissmissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
            id: 'message_policy_pushed_error'
          }
        ]);

      } else if (err.response.status === 404) {

        setFlashbarItems([
          {
            type: 'warning',
            header: 'Policy not found',
            content: 'The policy number was not found in PIMS. Please select a policy which is designated for inpsection.',
            dismissible: true,
            dissmissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
            id: 'message_policy_not_found'
          }
        ]);
        
      } else {

        setFlashbarItems([
          {
            type: 'error',
            header: 'Policy fetch failed',
            content: `An error was encountered retrieving the information for this policy. Please try again. Contact help center if the issue persists. 
              Response code received was ${err.response.status}`,
            dismissible: true,
            dissmissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
            id: 'message_policy_fetch_error'
          }
        ]);

      }

    }

    setButtonDisabled(false);

  };

  const handlePolicyChange = () => {
    setDisplayModal(true);
  };

  const changePolicy = () => {
    setPolicyInfo(undefined);
    setDisplayModal(false);
  };


  const tabs = [
    {
      label: 'Add Photos',
      id: 'onePhoto',
      content: <MultiUpload policyNum={policyInfo ? policyInfo.policyNumber : undefined} />,
    },
    {
      label: 'Photos',
      id: 'photosUploaded',
      content:
        <ShowPhotos policyNum={policyInfo ? policyInfo.policyNumber : undefined} onPushToPIMS={() => {
          setPolicyInfo(undefined);
          setFlashbarItems([
            {
              type: 'success',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setFlashbarItems([]),
              content: (
                <>
                  Push to PIMS successful!
                </>
              ),
              id: 'message_PIMS_push_success'
            }
          ]);
        }} />
    }
  ];


  return (
    <>
      <TopNavigation
        identity={{
          title: 'PIMS Mobile Upload Tool',
          href: '#',
        }}
      />
      <AppLayout
        headerSelector="#top-nav"
        ariaLabels={{
          navigation: 'Navigation drawer',
          navigationClose: 'Close navigation drawer',
          navigationToggle: 'Open navigation drawer',
          notifications: 'Notifications',
          tools: 'Help panel',
          toolsClose: 'Close help panel',
          toolsToggle: 'Open help panel',
        }}
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout header={<Header variant='h3'>{user ? `Hello ${user.username}` : ''}</Header>}>
            <Modal
              header='Change Policy?'
              visible={displayModal}
              onDismiss={() => setDisplayModal(false)}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => setDisplayModal(false)} variant="link">Cancel</Button>
                    <Button onClick={changePolicy} variant="primary">Confirm</Button>
                  </SpaceBetween>
                </Box>
              }
            >Are you sure you want to change to another policy?</Modal>
            <SpaceBetween size="l">
              <Flashbar items={flashbarItems} />
              {policyInfo ? <>
                <GeneralConfig
                  policyNum={policyInfo.policyNumber}
                  insuredAddress={policyInfo.address}
                  insuredName={policyInfo.insuredName}
                  handlePolicyChange={handlePolicyChange} />
                <Tabs tabs={tabs} ariaLabel="Resource details" />
              </> :
                <PolicyNumberInput disabled={buttonDisabled} handlePolicyNumberSubmit={handlePolicySubmit} />}
            </SpaceBetween>
          </ContentLayout>
        }
      />
    </>
  );

};

export default AppContainer;