import { useState, useEffect } from 'react';
import PhotoView from './PhotoView';
import { SpaceBetween, Spinner, Button, Flashbar, Box, Modal } from '@cloudscape-design/components';
import React from 'react';
import PropTypes from 'prop-types';
import { fetchPolicyPhotos, processPolicyPhotos } from '../api';

const ShowPhotos = (props) => {
  const policyNum = props.policyNum;
  const [loading, setLoading] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);

  const pushPhotosToPIMS = async () => {

    setFlashbarItems([
      {
        type: 'info',
        loading: true,
        content:
          'Pushing to PIMS in progres.',
        dismissible: true,
        onDismiss: () => setFlashbarItems([]),
        id: 'loading_push_to_PIMS'
      }
    ]);

    const response = await processPolicyPhotos(policyNum);

    if (response.status === 200) {

      setPhotos([]);

      props.onPushToPIMS();

    } else {
      setFlashbarItems([
        {
          type: 'error',
          header: 'Push to PIMS failed',
          content: `An error was encountered pushing these photos to PIMS. Please try again. Contact help center if the issue persists. 
            Response code received was ${response.status}`,
          dismissible: true,
          dissmissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([]),
          id: 'message_pims_push_error'
        }
      ]);
    }
  };

  const removePhoto = (UUID) => {
    const newArray = [];
    for (const photo of photos) {
      if (photo.uuid !== UUID) {
        newArray.push(photo);
      }
    }

    setPhotos(newArray);

  };

  useEffect(() => {

    const fetchPhotos = async () => {

      const response = await fetchPolicyPhotos(policyNum);

      if (response.status === 200) {
        setPhotos(response.data.photos);
        setLoading(false);
      } else {
        setFlashbarItems([
          {
            type: 'error',
            header: 'Photo retrieval failed',
            content: `An error was encountered retrieving the photos for this policy. Please try again. Contact help center if the issue persists. 
              Response code received was ${response.status}`,
            dismissible: true,
            dissmissLabel: 'Dismiss message',
            onDismiss: () => setFlashbarItems([]),
            id: 'message_photo_fetch_error'
          }
        ]);
      }
    };

    try {
      fetchPhotos();
    } catch (err) {

      setFlashbarItems([
        {
          type: 'error',
          header: 'Photo retrieval failed',
          content: `An error was encountered retrieving the photos for this policy. Please try again. Contact help center if the issue persists. 
            Response code received was ${err.response.status}`,
          dismissible: true,
          dissmissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([]),
          id: 'message_photo_fetch_error'
        }
      ]);

    }

  }, []);

  const PhotoContent = () => {
    return (
      <SpaceBetween size='m'>
        {photos.length > 0 ?
          <>
            <Button onClick={() => setDisplayModal(true)} variant='primary'>Send Photos to PIMS</Button>
            {photos.map(photo => (
              <PhotoView
                key={photo.uuid}
                policyNumber={policyNum}
                UUID={photo.uuid}
                photoUrl={photo.url}
                location={photo.location}
                note={photo.note}
                photoName={photo.name}
                editable={true}
                removePhoto={removePhoto} />
            ))}
          </> :
          <Box variant='h5' textAlign='center'>
            There are currently no photos to display for this policy. When you upload photos, they will appear here.
          </Box>
        }

      </SpaceBetween>
    );
  };

  return (
    <SpaceBetween size='s'>
      <Modal
        header='Push to PIMS?'
        visible={displayModal}
        onDismiss={() => setDisplayModal(false)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setDisplayModal(false)} variant="link">Cancel</Button>
              <Button onClick={() => {
                pushPhotosToPIMS();
                setDisplayModal(false);
              }} variant="primary">Send to PIMS</Button>
            </SpaceBetween>
          </Box>
        }
      >Are you sure you want to submit these photos to PIMS? Once you do, you will no longer be able to upload photos for this policy from the Mobile Upload tool.</Modal>
      <Flashbar items={flashbarItems} />
      {loading ? <Spinner /> : <PhotoContent />}
    </SpaceBetween>
  );
};

ShowPhotos.propTypes = {
  policyNum: PropTypes.string.isRequired,
  onPushToPIMS: PropTypes.func.isRequired
};

export default ShowPhotos;